exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-diensten-index-tsx": () => import("./../../../src/pages/diensten/index.tsx" /* webpackChunkName: "component---src-pages-diensten-index-tsx" */),
  "component---src-pages-diensten-kmo-portefeuille-tsx": () => import("./../../../src/pages/diensten/kmo-portefeuille.tsx" /* webpackChunkName: "component---src-pages-diensten-kmo-portefeuille-tsx" */),
  "component---src-pages-diensten-ontwerp-veilige-software-tsx": () => import("./../../../src/pages/diensten/ontwerp-veilige-software.tsx" /* webpackChunkName: "component---src-pages-diensten-ontwerp-veilige-software-tsx" */),
  "component---src-pages-diensten-strategie-en-management-tsx": () => import("./../../../src/pages/diensten/strategie-en-management.tsx" /* webpackChunkName: "component---src-pages-diensten-strategie-en-management-tsx" */),
  "component---src-pages-diensten-test-op-veiligheid-tsx": () => import("./../../../src/pages/diensten/test-op-veiligheid.tsx" /* webpackChunkName: "component---src-pages-diensten-test-op-veiligheid-tsx" */),
  "component---src-pages-en-blog-index-tsx": () => import("./../../../src/pages/en/blog/index.tsx" /* webpackChunkName: "component---src-pages-en-blog-index-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-services-index-tsx": () => import("./../../../src/pages/en/services/index.tsx" /* webpackChunkName: "component---src-pages-en-services-index-tsx" */),
  "component---src-pages-en-services-secure-by-design-tsx": () => import("./../../../src/pages/en/services/secure-by-design.tsx" /* webpackChunkName: "component---src-pages-en-services-secure-by-design-tsx" */),
  "component---src-pages-en-services-security-testing-tsx": () => import("./../../../src/pages/en/services/security-testing.tsx" /* webpackChunkName: "component---src-pages-en-services-security-testing-tsx" */),
  "component---src-pages-en-services-sme-e-wallet-tsx": () => import("./../../../src/pages/en/services/sme-e-wallet.tsx" /* webpackChunkName: "component---src-pages-en-services-sme-e-wallet-tsx" */),
  "component---src-pages-en-services-strategy-and-management-tsx": () => import("./../../../src/pages/en/services/strategy-and-management.tsx" /* webpackChunkName: "component---src-pages-en-services-strategy-and-management-tsx" */),
  "component---src-pages-en-tools-index-tsx": () => import("./../../../src/pages/en/tools/index.tsx" /* webpackChunkName: "component---src-pages-en-tools-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tools-index-tsx": () => import("./../../../src/pages/tools/index.tsx" /* webpackChunkName: "component---src-pages-tools-index-tsx" */),
  "component---src-templates-blog-post-en-tsx": () => import("./../../../src/templates/blog-post-en.tsx" /* webpackChunkName: "component---src-templates-blog-post-en-tsx" */),
  "component---src-templates-blog-post-nl-tsx": () => import("./../../../src/templates/blog-post-nl.tsx" /* webpackChunkName: "component---src-templates-blog-post-nl-tsx" */)
}

